//

import React, { useEffect, useState } from "react";
import client, { urlFor } from "../../sanityClient";
import styles from "./BlogHeader.module.css";

const BlogHeader = () => {
  const [data, setData] = useState({
    heading: "",
    mainHeading: "",
    imageUrl: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = '*[_type == "blogHeader"][0]';
        const result = await client.fetch(query);
        setData({
          heading: result.heading,
          mainHeading: result.mainHeading,
          imageUrl: urlFor(result.imageUrl).url(), // use urlFor here
        });
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <section className={styles.aboutHero}>
      <div>
        <p className={styles.heading}>{data.heading}</p>
      </div>
      <h1 className={styles.mainHeading}>{data.mainHeading}</h1>
      <div className={styles.imageContainer}>
        <img src={data.imageUrl} className={styles.heroImage} />
      </div>
    </section>
  );
};

export default BlogHeader;
