import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid"; // Import uuid
import client from "../sanityClient"; // Make sure to import your Sanity client
import styles from "./ContactForm.module.css";
import ContactInfoCard from "./contactInfoCard/ContactInfoCard";
import ReCAPTCHA from "react-google-recaptcha";
import FormfacadeEmbed from "@formfacade/embed-react";

const ContactForm = () => {
  // const [verified, setVerified] = useState(false);
  // const [formData, setFormData] = useState({
  //   firstName: "",
  //   lastName: "",
  //   email: "",
  //   company: "",
  //   phone: "",
  //   message: "",
  // });

  // const [content, setContent] = useState(null);

  // const fetchData = async () => {
  //   try {
  //     const result = await client.fetch('*[_type == "contactForm"][0]');
  //     setContent(result);
  //   } catch (error) {
  //     console.error("Error fetching data from Sanity:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // Handle input change for the form
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  // Handle form submission
  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!content) {
  //     console.error("No content found in Sanity CMS");
  //     return;
  //   }

  //   try {
  //     await client
  //       .patch(content._id) // Target the specific document
  //       .setIfMissing({ formSubmissions: [] }) // Ensure the `formSubmissions` array exists
  //       .append("formSubmissions", [
  //         {
  //           _key: uuidv4(), // Unique key for the submission
  //           firstName: formData.firstName,
  //           lastName: formData.lastName,
  //           email: formData.email,
  //           company: formData.company,
  //           phone: formData.phone,
  //           message: formData.message,
  //           submittedAt: new Date().toISOString(), // Timestamp for submission
  //         },
  //       ])
  //       .commit(); // Commit the changes to Sanity

  //     alert("Form submitted successfully!");

  //     // Reset form fields after submission
  //     setFormData({
  //       firstName: "",
  //       lastName: "",
  //       email: "",
  //       company: "",
  //       phone: "",
  //       message: "",
  //     });
  //     // setCaptchaValue(null);
  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //   }
  // };

  //recaptcha function
  // const handleCaptchaChange = (value) => {
  //   console.log("value", value);
  //   setVerified(true);
  // };

  return (
    <div className={styles.contactFormContainer}>
      <div className={styles.rightCol}>
        <ContactInfoCard />
      </div>
      <div lassName={styles.leftCol}>
        <FormfacadeEmbed
          formFacadeURL="https://formfacade.com/include/100623680019033526018/form/1FAIpQLSeh8tFjUO9WDyxTFViQnNXeFen5WFTk4cNvsGFM5uCfG0hkMA/classic.js/?div=ff-compose"
          onSubmitForm={() => console.log("Form submitted")}
        />
      </div>
      {/* <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSeh8tFjUO9WDyxTFViQnNXeFen5WFTk4cNvsGFM5uCfG0hkMA/viewform?embedded=true"
        width="640"
        height="1126"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Loading…
      </iframe> */}
      {/* <div className={styles.leftCol}>
        <h2 className={styles.heading}>Get in touch</h2>
        <p className={styles.subheading}>
          Our friendly team would love to hear from you.
        </p>

        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.row}>
            <div className={styles.field}>
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className={styles.field}>
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.field}>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className={styles.field}>
              <label htmlFor="company">Your Company Name</label>
              <input
                type="text"
                id="company"
                name="company"
                value={formData.company}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.fieldFullWidth}>
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.fieldFullWidth}>
              <label htmlFor="message">Message</label>
              <textarea
                className={styles.textareaWidth}
                id="message"
                name="message"
                rows="5"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
          </div>
          <ReCAPTCHA
            sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
            onChange={handleCaptchaChange}
          />
          <div className={styles.btnDiv}>
            <div className={styles.row}>
              <button
                type="submit"
                className={styles.buttonOne}
                disabled={!verified}
              >
                Send message
              </button>
            </div>
          </div>
        </form>
      </div> */}
    </div>
  );
};

export default ContactForm;
