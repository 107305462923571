import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./WireShot.module.css";
import client from "../../sanityClient"; // Adjust path as necessary
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(client);

const urlFor = (source) => builder.image(source);

const WireShot = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = '*[_type == "wireShot"][0]';
        const result = await client.fetch(query);
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  if (!data) return <p></p>;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>{data.title}</h1>
        <div className={styles.secondDiv}>
          <p className={styles.description}>{data.description}</p>
          <div className={styles.btnDiv}>
            <Link to="/wireshot" className={styles.button}>
              {data.buttonText}
            </Link>
          </div>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.leftColumn}>
          {data.cards.map((card, index) => (
            <div key={index} className={styles.card}>
              {card.image && (
                <img
                  src={urlFor(card.image).url()}
                  alt={card.title}
                  className={styles.cardImage}
                />
              )}
              <h2 className={styles.cardTitle}>{card.title}</h2>
              <p className={styles.cardDescription}>{card.description}</p>
            </div>
          ))}
        </div>
        <div className={styles.rightColumn}>
          {data.verticalImage && (
            <img
              src={urlFor(data.verticalImage).url()}
              alt="Vertical"
              className={styles.verticalImage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WireShot;
