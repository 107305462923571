import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Navbar.module.css";
import client, { urlFor } from "../sanityClient";
import tick from "./check.png";

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [navbarData, setNavbarData] = useState({
    logo: null,
    navItems: [],
    contactButtonText: "",
  });
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const navigate = useNavigate();

  useEffect(() => {
    const fetchNavbarData = async () => {
      const query = `*[_type == "navbar"][0]`;
      const data = await client.fetch(query);
      setNavbarData(data);
      setIsLoading(false); // Data fetched, stop loading
    };

    fetchNavbarData();
  }, []);

  const toggleDropdown = (key) => {
    setDropdownOpen((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prev) => !prev);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const handleDropdownItemClick = (dropdownItem) => {
    if (dropdownItem.metalName) {
      navigate(`/product-detail/${dropdownItem.metalName}`);
    } else if (dropdownItem.link) {
      navigate(dropdownItem.link);
    }
    setDropdownOpen({});
    closeMobileMenu();
  };

  if (isLoading) {
    // While data is loading, render a hidden navbar to prevent the button flash
    return <nav className={`${styles.navbar} ${styles.hidden}`}></nav>;
  }

  return (
    <nav className={styles.navbar}>
      <div className={styles.logo}>
        {navbarData.logo && (
          <Link to="/" onClick={closeMobileMenu}>
            <img src={urlFor(navbarData.logo).url()} alt="logo" />
          </Link>
        )}
      </div>
      <div className={styles.hamburger} onClick={toggleMobileMenu}>
        <img src={require("./menu.png")} alt="menu" />
      </div>
      <ul className={`${styles.navItems} ${mobileMenuOpen ? styles.show : ""}`}>
        {navbarData.navItems.map((item, index) => (
          <li
            key={index}
            className={styles.navItem}
            onMouseEnter={() => item.dropdown && toggleDropdown(index)}
            onMouseLeave={() => item.dropdown && toggleDropdown(index)}
          >
            <Link
              className={styles.dropItem}
              to={item.link}
              onClick={() => !item.dropdown && closeMobileMenu()}
            >
              {item.title}
            </Link>
            {item.dropdown && dropdownOpen[index] && (
              <ul className={styles.dropdown}>
                {item.dropdown.map((dropdownItem, i) => (
                  <li key={i} className={styles.dropdownItem}>
                    <a
                      onClick={() => handleDropdownItemClick(dropdownItem)}
                      style={{ cursor: "pointer" }}
                    >
                      {dropdownItem.title}
                      <img
                        src={tick}
                        alt="tick icon"
                        className={styles.tickIcon}
                      />
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
        <li className={styles.navItem}>
          <Link
            to="/contact-us"
            className={styles.contactButton}
            onClick={closeMobileMenu}
          >
            {navbarData.contactButtonText}
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
