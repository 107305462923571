import React from "react";
import ContactForm from "./ContactForm";
import ContactBanner from "./contactBanner/ContactBanner";
import FormfacadeEmbed from "@formfacade/embed-react";

const Contact = () => {
  return (
    <div>
      <ContactBanner />

      <ContactForm />
    </div>
  );
};

export default Contact;
