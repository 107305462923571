import React, { useState, useEffect } from "react";
import styles from "./ProductSlider.module.css";
import { client, urlFor } from "../../sanityClient";

const ProductSlider = ({ productbannerDescription, imgSrc, title }) => {
  return (
    <div className={styles.sliderContainer}>
      <div className={styles.slider}>
        <div className={styles.sliderWrapper}>
          <div className={styles.slide}>
            <div className={styles.textContent}>
              <h2 className={styles.title}>{title} Cut Wire Shot</h2>
              <p className={styles.description}>{productbannerDescription}</p>
            </div>
            <div className={styles.imageContent}>
              <img
                src={urlFor(imgSrc).url()}
                alt={title}
                className={styles.image}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSlider;
