// src/components/BlogDetail/BlogDetail.js
import React from "react";
import { useLocation } from "react-router-dom";
import styles from "./BlogDetail.module.css";
import { urlFor } from "../sanityClient"; // Import the urlFor function

const BlogDetailPage = () => {
  const location = useLocation();
  const { blog } = location.state || {};

  if (!blog) {
    return <p></p>;
  }

  return (
    <div className={styles.blogDetailContainer}>
      <div className={styles.blogDetailWrapper}>
        <div className={styles.metaInfo}>
          <span className={styles.category}>{blog.category.toUpperCase()}</span>
          <span className={styles.date}>
            {new Date(blog.datePosted).toLocaleDateString()}
          </span>
        </div>
        <div className={styles.headeWrapper}>
          <h1 className={styles.title}>{blog.title}</h1>
          <img
            className={styles.blogImage}
            src={urlFor(blog.image).url()} // Use the urlFor function
            alt={blog.title}
          />
        </div>

        {blog.content.map((section, index) => (
          <div key={index} className={styles.section}>
            <h2 className={styles.subTitle}>{section.subTitle}</h2>
            <p className={styles.paragraph}>{section.paragraph}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogDetailPage;
