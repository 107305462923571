import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./BlogsList.module.css";
import client from "../../sanityClient"; // Adjust the path as needed
import rightArrow from "./rightArrow.png";
import imageUrlBuilder from "@sanity/image-url";
import Pagination from "../Pagination/Pagination";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

const BlogsList = () => {
  const [blogs, setBlogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [blogsPerPage] = useState(3); // Show 3 blogs initially
  const navigate = useNavigate();

  useEffect(() => {
    client
      .fetch(
        `*[_type == "blogPost"]{
          _id,
          title,
          category,
          shortText,
          datePosted,
          readingTime,
          image{
            asset->{
              _id,
              url
            }
          },
          content[] {
            subTitle,
            paragraph
          }
        }`
      )
      .then((data) => setBlogs(data))
      .catch(console.error);
  }, []);

  // Get the blogs filtered by search term
  const filteredBlogs = blogs.filter(
    (blog) =>
      blog.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      blog.shortText.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Get current blogs for the page
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const handleReadMore = (blog) => {
    navigate(`/blog/${blog._id}`, { state: { blog } });
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className={styles.blogContainer}>
      <div className={styles.blogWrapper}>
        {blogs.length > 0 && (
          <>
            {/* Search Box */}
            <div className={styles.searchContainer}>
              <input
                type="text"
                className={styles.searchBox}
                placeholder="Search blogs..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            {/* Blog Cards */}
            <div className={styles.cardsRow}>
              {currentBlogs.map((blog) => (
                <div
                  key={blog._id}
                  className={styles.card}
                  onClick={() => handleReadMore(blog)}
                >
                  <div className={styles.cardHeader}>
                    <div className={styles.circleDot}></div>
                    <span className={styles.time}>{blog.readingTime}</span>
                  </div>
                  <h2 className={styles.blogHeading}>{blog.title}</h2>
                  <img
                    className={styles.blogImage}
                    src={urlFor(blog.image).url()}
                    alt={blog.title}
                  />
                  <div className={styles.cardFooter}>
                    <p className={styles.shortText}>{blog.shortText}</p>
                    <button
                      className={styles.readMoreButton}
                      onClick={() => handleReadMore(blog)}
                    >
                      <img
                        className={styles.arrowImg}
                        src={rightArrow}
                        alt="right arrow"
                      />
                    </button>
                  </div>
                </div>
              ))}
            </div>

            {/* Pagination */}
            <Pagination
              blogsPerPage={blogsPerPage}
              totalBlogs={filteredBlogs.length}
              paginate={paginate}
              currentPage={currentPage} // Pass currentPage to Pagination
            />
          </>
        )}
      </div>
    </div>
  );
};

export default BlogsList;
