import React from "react";
import AboutHero from "./AboutHeroS";
import CoreFocus from "./CoreFocus/CoreFocus";
import Specification from "./Specification/Specification";
import QualityMeasures from "./QualityMeasures/QualityMeasures";
import SQuality from "../SQuality/SQuality";
import PopularBlog from "../BlogPage/PopularBlog/PopularBlog";

const AboutTwoPage = () => {
  return (
    <div>
      <AboutHero />
      <CoreFocus />
      <Specification />
      <QualityMeasures />
      <SQuality />
      <PopularBlog />
    </div>
  );
};

export default AboutTwoPage;
