import React from "react";
import BlogHeader from "./BlogHeader/BlogHeader";
import BlogsList from "./Blogs/BlogsList";
import PopularBlog from "./PopularBlog/PopularBlog";

const BlogPage = () => {
  return (
    <div>
      <BlogHeader />
      <BlogsList />
    </div>
  );
};

export default BlogPage;
