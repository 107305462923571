import React, { useEffect, useState } from "react";
import styles from "./AboutStat.module.css";
import client from "../../sanityClient";

const AboutStat = () => {
  const [stats, setStats] = useState([]);

  useEffect(() => {
    client
      .fetch(`*[_type == "aboutStats"]{stats[]{number, title, description}}`)
      .then((data) => {
        if (data && data.length > 0) {
          setStats(data[0].stats);
        }
      })
      .catch((error) => {
        console.error("Error fetching AboutStat data:", error);
      });
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {stats.map((stat, index) => (
          <div key={index} className={styles.card}>
            <div className={styles.number}>{stat.number}</div>
            <div className={styles.title}>{stat.title}</div>
            <div className={styles.description}>{stat.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AboutStat;
