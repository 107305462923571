import React, { useState, useEffect } from "react";
import styles from "./FAQ.module.css";
import dropIcon from "./fi_2985150 (2).png";
import client from "../../sanityClient"; // Adjust the path to your sanityClient

const Faq = () => {
  const [faqData, setFaqData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        const query = '*[_type == "faq"]{title, faqs[]{question, answer}}';
        const data = await client.fetch(query);
        if (data && data.length > 0) {
          setFaqData(data[0]); // Expecting an array with at least one item
        }
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFAQs();
  }, []);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{faqData?.title}</h2>

      <div className={styles.faqContainer}>
        <div className={styles.column}>
          {isLoading && <div className={styles.loading}></div>}
          {!isLoading &&
            (!faqData || !faqData.faqs || faqData.faqs.length === 0) && (
              <div>No FAQs available at the moment.</div>
            )}
          {!isLoading &&
            faqData?.faqs?.length > 0 &&
            faqData.faqs.map((faq, index) => (
              <div key={index} className={styles.faqItem}>
                <div
                  className={styles.faqHeader}
                  onClick={() => toggleFAQ(index)}
                >
                  <span className={styles.faqQuestion}>{faq.question}</span>
                  <span className={styles.dropdown}>
                    <img src={dropIcon} alt="dropIcon" />
                  </span>
                </div>
                {activeIndex === index && (
                  <div className={styles.faqAnswer}>{faq.answer}</div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
