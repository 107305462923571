import React, { useEffect, useState } from "react";
import client from "../sanityClient";
import styles from "./AboutHero.module.css";

const AboutHero = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "aboutHeroS"]{
          topList,
          mainHeading,
          description,
          "imageUrl": image.asset->url
        }`
      )
      .then((data) => setContent(data[0]))
      .catch(console.error);
  }, []);

  if (!content) return <div></div>;

  return (
    <div className={styles.wrapper}>
      <section className={styles.aboutHero}>
        <ul className={styles.topList}>
          {content.topList.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <h1 className={styles.mainHeading}>{content.mainHeading}</h1>
        <p className={styles.description}>{content.description}</p>
        <div className={styles.imageContainer}>
          <img
            src={content.imageUrl}
            alt="About Hero"
            className={styles.heroImage}
          />
        </div>
      </section>
    </div>
  );
};

export default AboutHero;
