// src/App.js
import React, { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Advantage from "./components/contents/Advantage/Advantage";
import Banner from "./components/contents/Banner/Banner";
import Benefit from "./components/contents/Benefit/Benefit";
import Blogs from "./components/contents/blogs/Blogs";
import ChooseUs from "./components/contents/chooseUs/ChooseUs";
import AboutUs from "./components/contents/AboutUs/AboutUs";
import Commitment from "./components/contents/Commitment/Commitment";
import Customer from "./components/contents/Customer/Customer";
import HeroSection from "./components/contents/HeroSection/HeroSection";
import Product from "./components/contents/Product/Product";
import FAQ from "./components/contents/FAQ/Faq";
import Footer from "./components/contents/footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import WireShot from "./components/contents/WireShot/WireShot";
import AboutOne from "./components/AboutOne/AboutOne";
import AboutTwoPage from "./components/AboutTwo/AboutTwoPage";
import BlogPage from "./components/BlogPage/BlogPage";
import BlogDetailPage from "./components/BlogDetail/BlogDetailPage";
import IndustriesServed from "./components/IndustriesServed/IndustriesServed";
import WireShotDetail from "./components/WireShots/WireShotDetail";
import ProductDetail from "./components/productDetails/ProductDetail";
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy";
import CookiesPolicy from "./components/cookiesPolicy/CookiesPolicy";
import Contact from "./components/contactForm/Contact";
import ImageSlider from "./components/contents/videoSlider/Video";

const Company = () => <AboutOne />;
const AboutTwo = () => <AboutTwoPage />;
const Blog = () => <BlogPage />;

const Home = () => (
  <>
    <HeroSection />
    <AboutUs />
    <Product />
    <Customer />
    <Commitment />
    <Banner />
    <WireShot />
    <ImageSlider />
    <Advantage />
    <ChooseUs />
    <Benefit />
    <Blogs />
    <FAQ />
  </>
);

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about/company" element={<Company />} />
          <Route path="/about/about-splus" element={<AboutTwo />} />
          <Route
            path="/product-detail/:metalName"
            element={<ProductDetail />}
          />
          <Route path="/industries-served" element={<IndustriesServed />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogDetailPage />} />
          <Route path="/wireshot" element={<WireShotDetail />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
