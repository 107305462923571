import React from "react";
import styles from "./Performance.module.css";

const Card = ({ icon, title, description }) => {
  return (
    <div className={styles.card}>
      <div className={styles.icon}>{icon}</div>
      <h3 className={styles.cardTitle}>{title}</h3>
      <p className={styles.cardDescription}>{description}</p>
    </div>
  );
};

const Performance = ({ performance, brochure }) => {
  if (!performance || performance.length === 0) {
    return <div>No performance data available.</div>;
  }

  return (
    <div className={styles.performanceContainer}>
      <h2 className={styles.heading}>Performance</h2>
      <div className={styles.cardGrid}>
        {performance.map((item, index) => (
          <Card
            key={index} // Ensure item.id exists or use item.title as a fallback
            icon={item.icon}
            title={item.title}
            description={item.description}
          />
        ))}
      </div>
      {brochure && (
        <div className={styles.downloadContainer}>
          <a
            href={brochure} // Directly use the brochure URL
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            <button className={styles.downloadButton}>Download Brochure</button>
          </a>
        </div>
      )}
    </div>
  );
};

export default Performance;
