import React, { useEffect, useState } from "react";
import client from "../../sanityClient";
import styles from "./Banner.module.css";
import img from "./Ellipse 7 (1).png";
import img2 from "./Ellipse 8 (1).png";
import { Link } from "react-router-dom";

const Banner = () => {
  const [bannerData, setBannerData] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `*[_type == "banner"][0]`;
        const data = await client.fetch(query);
        setBannerData(data);
        setIsLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Error fetching banner data: ", error);
        setIsLoading(false); // Ensure loading stops on error
      }
    };

    fetchData();
  }, []);

  // Return null or loader if still loading
  if (isLoading) {
    return null; // You can return a loader component or an empty div
  }

  // Use && to conditionally render content when bannerData is available
  return (
    <div className={styles.banner}>
      <div className={styles.bannerDiv}>
        {bannerData?.heading && (
          <h1 className={styles.heading}>{bannerData.heading}</h1>
        )}
        {bannerData?.description && (
          <p className={styles.description}>{bannerData.description}</p>
        )}
        <div className={styles.btnDiv}>
          {bannerData?.buttonText && (
            <Link to="/contact-us" className={styles.quoteButton}>
              {bannerData.buttonText}
            </Link>
          )}
        </div>
      </div>
      <div className={styles.imgoneDiv}>
        <img className={styles.img} src={img} alt="bgImg" />
      </div>
      <div className={styles.imgtwoDiv}>
        <img src={img2} className={styles.img2} alt="bgImg" />
      </div>
    </div>
  );
};

export default Banner;
