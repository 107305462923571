import React, { useEffect, useState } from "react";
import styles from "./Advantage.module.css";
import client from "../../sanityClient";

const Advantage = () => {
  const [advantages, setAdvantages] = useState([]);
  const [section, setSection] = useState({ title: "", description: "" });
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sectionData =
          await client.fetch(`*[_type == "advantageSection"][0]{
          sectionTitle,
          sectionDescription,
          advantages[] {
            icon {
              asset->{
                _id,
                url
              }
            },
            title,
            description
          }
        }`);

        // Set the section data
        setSection({
          title: sectionData.sectionTitle,
          description: sectionData.sectionDescription,
        });
        setAdvantages(sectionData.advantages || []); // Set advantages (empty array if none found)
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Data loading completed (success or failure)
      }
    };

    fetchData();
  }, []);

  if (loading) return null; // Return null during loading

  return (
    <div className={styles.container}>
      {section?.title && <h2 className={styles.title}>{section.title}</h2>}
      {section?.description && (
        <p className={styles.description}>{section.description}</p>
      )}
      <div className={styles.cardsContainer}>
        {advantages.length > 0 ? (
          advantages.map((adv, index) => (
            <div className={styles.card} key={index}>
              <div className={styles.icon}>
                {adv?.icon?.asset?.url && (
                  <img
                    src={adv.icon.asset.url}
                    alt={adv.title}
                    className={styles.iconImage}
                  />
                )}
              </div>
              {adv?.title && <h3 className={styles.cardTitle}>{adv.title}</h3>}
              {adv?.description && (
                <p className={styles.cardDescription}>{adv.description}</p>
              )}
            </div>
          ))
        ) : (
          <p>No advantages found</p>
        )}
      </div>
    </div>
  );
};

export default Advantage;
