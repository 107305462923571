import React, { useState, useEffect } from "react";
import client from "../../sanityClient"; // Adjust the path according to your setup
import styles from "./IntroAbout.module.css";

const IntroAbout = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "introAbout"][0]{
          qualities,
          heading,
          description,
          "aboutImageUrl": aboutImage.asset->url,
          "bottomImageUrl": bottomImage.asset->url
        }`
      )
      .then((data) => setContent(data))
      .catch(console.error);
  }, []);

  if (!content) return <div></div>;

  return (
    <div className={styles.container}>
      <div className={styles.leftCol}>
        <p className={styles.qualities}>{content.qualities.join(" | ")}</p>
        <h2 className={styles.heading}>
          {content.heading}
          {/* <br /> */}
          {/* {content.heading.split(" ")[1]} */}
        </h2>
        <p className={styles.description}>{content.description}</p>
      </div>
      <div className={styles.rightCol}>
        <img
          src={content.aboutImageUrl}
          alt="About Suntec"
          className={styles.image}
        />
      </div>
      <div className={styles.fullWidthImageWrapper}>
        <img
          src={content.bottomImageUrl}
          alt="Bottom"
          className={styles.fullWidthImage}
        />
      </div>
    </div>
  );
};

export default IntroAbout;
