import { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";


const client = createClient({
  projectId: "o46e8fg2",
  dataset: "production",
  token: "skvrLqlStUIRR3nLifHgesXo8xfA1mxjNJGITwmVBJvRosh2X7INpw4DK86UPOJcXtOUsWPOUFm1xyS0g9Wz8soHqgqX6tyHImYmm5aM4sQlbguhwS0xmLsVr0wH8DR2QfJqp7p4qf9cWaq1RMVCN2XCQM1UphA0U95IhCiQBYWzezJXRQGp",
  useCdn: true

});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);

export default client;
