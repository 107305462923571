import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import client from "../../sanityClient"; // Adjust the path as needed
import styles from "./PopularBlog.module.css";
import rightArrow from "./rightArrow.png";

// if the blog is marked popular in blog list it will be shown in popular section
const PopularBlog = () => {
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    client
      .fetch(
        `*[_type == "blogPost"]{
          _id,
          title,
          category,
          shortText,
          datePosted,
          readingTime,
          image{
            asset->{
              _id,
              url
            }
          },
          content[] {
            subTitle,
            paragraph
          },
          isPopular // Fetch the isPopular field
        }`
      )
      .then((data) => {
        // Filter the blogs to only include popular ones
        const popularBlogs = data.filter((blog) => blog.isPopular);
        setBlogs(popularBlogs);
      })
      .catch(console.error);
  }, []);

  const handleReadMore = (blog) => {
    navigate(`/blog/${blog._id}`, { state: { blog } });
  };

  return (
    <div className={styles.blogContainer}>
      <div className={styles.blogWrapper}>
        <div className={styles.headerRow}>
          <div className={styles.headerCol}>
            <h1 className={styles.headerText}>Popular Posts</h1>
            <p className={styles.paraText}>
              Stay informed and ahead with our expert insights, industry trends,
              and practical tips shared regularly on our blog.
            </p>
          </div>
          <div className={styles.descCol}>
            <Link to="/blog" className={styles.seeMoreButton}>
              Browse more
            </Link>
          </div>
        </div>

        <div className={styles.cardsRow}>
          {blogs.map((blog) => (
            <div
              key={blog._id}
              className={styles.card}
              onClick={() => handleReadMore(blog)}
            >
              <div className={styles.cardHeader}>
                <div className={styles.circleDot}></div>
                <span className={styles.time}>{blog.readingTime}</span>
              </div>
              <h2 className={styles.blogHeading}>{blog.title}</h2>
              <img
                className={styles.blogImage}
                src={blog.image.asset.url}
                alt={blog.title}
              />
              <div className={styles.cardFooter}>
                <p className={styles.shortText}>{blog.shortText}</p>
                <button
                  className={styles.readMoreButton}
                  onClick={() => handleReadMore(blog)}
                >
                  <img
                    className={styles.arrowImg}
                    src={rightArrow}
                    alt="right arrow"
                  />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PopularBlog;
