import React, { useEffect, useState } from "react";
import styles from "./ChooseUs.module.css";
import client from "../../sanityClient"; // Adjust the path as needed
import { Link } from "react-router-dom";

const ChooseUs = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `*[_type == "chooseUs"][0] {
          heading,
          description,
          ctaButtonText,
          mainImage {
            asset->{
              url
            }
          },
          cards[] {
            icon {
              asset->{
                url
              }
            },
            title,
            description
          }
        }`;
        const result = await client.fetch(query);
        setData(result);
        setLoading(false); // Data is loaded
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Stop loading on error
      }
    };

    fetchData();
  }, []);

  if (loading) return null; // Return null while loading

  return (
    <div className={styles.chooseUsContainer}>
      <div className={styles.leftCol}>
        {data?.heading && <h2 className={styles.heading}>{data.heading}</h2>}
        {data?.description && (
          <p className={styles.description}>{data.description}</p>
        )}
        {data?.ctaButtonText && (
          <div className={styles.btnDiv}>
            <Link className={styles.ctaButton} to="/contact-us">
              {data.ctaButtonText}
            </Link>
          </div>
        )}
        {data?.mainImage?.asset?.url && (
          <img
            src={data.mainImage.asset.url}
            alt="Why Choose Us"
            className={styles.image}
          />
        )}
      </div>
      <div className={styles.rightCol}>
        {data?.cards?.length > 0 &&
          data.cards.map((card, index) => (
            <div key={index} className={styles.card}>
              {card?.icon?.asset?.url && (
                <div className={styles.icon}>
                  <img src={card.icon.asset.url} alt={card.title} />
                </div>
              )}
              {card?.title && (
                <h3 className={styles.cardTitle}>{card.title}</h3>
              )}
              {card?.description && (
                <p className={styles.cardDescription}>{card.description}</p>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default ChooseUs;
