import React, { useEffect, useState } from "react";
import styles from "./ContactInfoCard.module.css";
import client from "../../sanityClient";
import imageUrlBuilder from "@sanity/image-url";
import Maps from "../contactBanner/Maps";

// Set up the Sanity image URL builder
const builder = imageUrlBuilder(client);

const urlFor = (source) => {
  return builder.image(source);
};

const ContactInfoCard = () => {
  const [contactInfo, setContactInfo] = useState(null);

  useEffect(() => {
    const fetchContactInfo = async () => {
      const query = `*[_type == "contactInfoCard"]{
        logo,
        squareImg,
        address,
        locationIcon,
        email,
        emailIcon,
        phone,
        phoneIcon,
        whatsapp,
        whatsappIcon
      }[0]`;
      const data = await client.fetch(query);
      setContactInfo(data);
    };
    fetchContactInfo();
  }, []);

  if (!contactInfo) return <div></div>;

  return (
    <div className={styles.card}>
      <div className={styles.logoDiv}>
        {/* <Maps /> */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1755.0687779895864!2d77.26613924442746!3d28.384913!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cde92303344cf%3A0x12afcc4a272495e6!2sSUNTEC%20ENTERPRISES!5e0!3m2!1sen!2snp!4v1727062375000!5m2!1sen!2snp"
          width="600"
          height="450"
          className={styles.mapDiv}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <div className={styles.infoCard}>
        <div className={styles.infoRow}>
          <img
            src={urlFor(contactInfo.locationIcon).url()}
            alt="Location Icon"
          />
          <span
            className={styles.infoText}
            dangerouslySetInnerHTML={{ __html: contactInfo.address }}
          />
        </div>

        <div className={styles.infoRow}>
          <img src={urlFor(contactInfo.emailIcon).url()} alt="Email Icon" />
          <span className={styles.infoText}>{contactInfo.email}</span>
        </div>

        <div className={styles.infoRow}>
          <img src={urlFor(contactInfo.phoneIcon).url()} alt="Phone Icon" />
          <span className={styles.infoText}>{contactInfo.phone}</span>
        </div>

        <div className={styles.infoRow}>
          <img
            src={urlFor(contactInfo.whatsappIcon).url()}
            alt="WhatsApp Icon"
          />
          <span className={styles.infoText}>{contactInfo.whatsapp}</span>
        </div>
      </div>
    </div>
  );
};

export default ContactInfoCard;
