import React, { useEffect, useState } from "react";
import styles from "./IndustryServedList.module.css";
import client from "../../sanityClient"; // Adjust the path to your sanity client

const IndustryServedList = () => {
  const [industries, setIndustries] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `*[_type == "industryWithCards"]{
          _id,
          title,
          description,
          imgSrc {
            asset->{
              url
            }
          },
          cards[]{
            title,
            description,
            icon {
              asset->{
                url
              }
            }
          }
        }`;

        const data = await client.fetch(query);
        setIndustries(data);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchData();
  }, []);

  const renderCards = (cards) => (
    <div className={styles.cardContainer}>
      {cards.map((card, index) => {
        const cardIconUrl = card.icon?.asset?.url; // Ensure the icon URL is available
        return (
          <div key={index} className={styles.card}>
            <div className={styles.cardIcon}>
              {cardIconUrl ? (
                <img src={cardIconUrl} alt={card.title} />
              ) : (
                <p>No Icon</p> // Fallback if icon URL is not available
              )}
            </div>
            <h3>{card.title}</h3>
            <p className={styles.indusDes}>{card.description}</p>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className={styles.industryList}>
      {industries.map((industry) => (
        <div key={industry._id} className={styles.industry}>
          <div className={styles.industryContent}>
            <div className={styles.textContent}>
              <h2 className={styles.title}>{industry.title}</h2>
              <hr className={styles.hrLine} /> <br />
              <p className={styles.description}>{industry.description}</p>
            </div>
            <div className={styles.industryImage}>
              {industry.imgSrc?.asset?.url ? (
                <img src={industry.imgSrc.asset.url} alt={industry.title} />
              ) : (
                <p>No Image</p> // Fallback if image URL is not available
              )}
            </div>
          </div>
          {renderCards(industry.cards)}
        </div>
      ))}
    </div>
  );
};

export default IndustryServedList;
