import React, { useState, useEffect, useRef } from "react";
import styles from "./Video.module.css"; // Updated name for clarity
import client from "../../sanityClient";

const ImageSlider = () => {
  const [images, setImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const slideIntervalRef = useRef(null);

  useEffect(() => {
    const fetchImages = async () => {
      const query = `*[_type == "imageSlider"]{
        images[] {
          "src": imageFile.asset->url,
          text
        }
      }`;
      const result = await client.fetch(query);
      if (result.length > 0) {
        setImages(result[0].images);
      }
    };

    fetchImages();
  }, []);

  useEffect(() => {
    if (images.length === 0) return;

    const startAutoSlide = () => {
      slideIntervalRef.current = setInterval(() => {
        setCurrentImage((prevImage) => (prevImage + 1) % images.length);
      }, 5000); // Slide every 5 seconds
    };

    startAutoSlide();

    return () => clearInterval(slideIntervalRef.current);
  }, [images]);

  const handleIndicatorClick = (index) => {
    setCurrentImage(index);
  };

  return (
    <div className={styles.sliderContainer}>
      {images.map((image, index) => (
        <div
          key={index}
          className={`${styles.imageContainer} ${
            index === currentImage ? styles.active : ""
          }`}
        >
          <img src={image.src} className={styles.image} alt={image.text} />
          <div className={styles.overlayText}>{image.text}</div>
        </div>
      ))}
      <div className={styles.indicators}>
        {images.map((_, index) => (
          <span
            key={index}
            className={`${styles.indicator} ${
              index === currentImage ? styles.activeIndicator : ""
            }`}
            onClick={() => handleIndicatorClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
